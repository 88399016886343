import api from '@/services/gateway/api';

const state = {
  smsList: null,
  smsTemplate: null,
  smsDepartment: null,
  errorMsg: null
};

const getters = {
  getSmsList: state => state.smsList,
  getTemplate: state => state.smsTemplate,
  getSmsDepartment: state => state.smsDepartment,
  getError: state=>state.errorMsg
};

const mutations = {
  setSmsList (state, data) {
    state.smsList = data;
    return state.smsList
  },
  setSmsTemplate (state, data) {
    state.smsTemplate = data;
  },
  setSmsDepartment (state, data) {
    state.smsDepartment = data
  },
  setErrorMsg (state, data) {
    state.errorMsg = data
  }
};

const actions = {
  actionGetSmsList ({ commit }, payload ){
    api.get('camp/sms/logs',{ params: payload})
      .then((response) => {
        commit('setSmsList', response.data.data.items);
      })
      .catch((error) => {
        commit('setErrorMsg', error)      
      });
  },

  actionGetTemplate ({ commit }, payload) {
    api.get('camp/sms/templates', { params: payload })
      .then((response) => {
        commit('setSmsTemplate', response.data.items);
      })
      .catch(() => {
      });
  },

  actionGetDepartments({commit}, payload) {
    api.get('camp/sms/departments', {params: payload})
    .then((response)=>{
      commit('setSmsDepartment', response.data.items);
    })
  } 
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};